<template>
    <div class="loaderPreview" :style="`background:${bg};`">
        <div v-if="previewBg" class="preview_background" style="
            position: absolute;
            top: 0;
            left: 0;
            width: 345px;
            height:570px;
            overflow: hidden;
            background: #fff;"></div>
          <div v-html="loaderState.text" style="margin-bottom:15px;z-index:9" v-if="loaderState.text&&loaderState.show&&selectedPage==='main'"></div>
          <div v-if="loaderState.icon&&loaderState.show&&selectedPage==='main'" :style="`-webkit-mask: url(${loaderState.icon}) no-repeat center;mask: url(${loaderState.icon}) no-repeat center;margin:0 auto;background-size:100% 100%;background-color:${loaderState.icon_color};height:${loaderState.size}px;width:${loaderState.size}px;`" ></div>
          <div v-if="selectedPage==='lang'">

            <div style="position:absolute;width:100%;height:100%;top:0;left:0;background:rgba(33,33,33,.46)">
            </div>
            <div :style="`background:${bgLang};padding:15px;background-repeat:no-repeat;background-size:100% 100%;`" style="position:absolute;border-top-right-radius:5px;display:flex;flex-direction:column;align-items:center;justify-content:center;border-top-left-radius:5px;background:#fff;height:200px;width:100%;bottom:0;left:0;">
              <div v-if="previewLangBg" class="preview_background_languages" style="
                position: absolute;
                bottom: 0;
                left: 0;
                width: 345px;
                height:200px;
                overflow: hidden;
                background: #fff;
                border-top-right-radius:5px;
                border-top-left-radius:5px;"></div>
              <div v-html="landing.branding.loading_langs.text" style="margin-bottom:15px;z-index:9;" v-if="landing.branding.loading_langs.text"></div>
              <div v-if="landing.branding.loading_langs.icon" :style="`-webkit-mask: url(${landing.branding.loading_langs.icon}) no-repeat center;mask: url(${landing.branding.loading_langs.icon}) no-repeat center;margin:0 auto;background-size:100% 100%;background-color:${landing.branding.loading_langs.icon_color};height:${landing.branding.loading_langs.size}px;width:${landing.branding.loading_langs.size}px;`" ></div>
            </div>
          </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        name:"LoaderPreview",
        computed:{
            ...mapState('loader',['loaderState','selectedPage','previewBg','previewLangBg']),
            ...mapState(['landing']),
            bgLang(){
                if(this.landing.branding.loading_langs.background[0] === '#'){
                  return this.landing.branding.loading_langs.background;
                }else{
                  return `url(${this.landing.branding.loading_langs.background}) no-repeat center / cover`;
                }
            },
            bg(){
                if(this.loaderState.show){
                if(this.loaderState.background[0] === '#'){
                    return this.loaderState.background;
                }else{
                    return `url(${this.loaderState.background}) no-repeat center / cover`;
                }
                }else{
                    return '#fff'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.loaderPreview{
    height:570px;
    width:100%;
    display: flex;
    padding:15px;
    justify-content: center;
    flex-direction: column;
}
</style>